import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Block, Loading, Notify } from 'notiflix';
import { NzI18nService, pt_BR } from 'ng-zorro-antd/i18n';
import { DomeComponent } from './layouts/dome/dome.component';
import { AuthenticationComponent } from './layouts/authentication/authentication.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, DomeComponent, AuthenticationComponent],
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'Dome.Web2';

  constructor(
    private i18n: NzI18nService
  ) {
    Loading.init({ svgColor: '#5b2e90' });
    Notify.init({ timeout: 2500 });
    Block.init({ svgColor: '#5b2e90', backgroundColor: '#f8f9faaa' });
    this.i18n.setLocale(pt_BR);
  }
}
