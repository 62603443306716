import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userValue;
        const roles: string[] = route?.data?.['roles'];

        if (user) {
            this.redirectUser(user, roles);
            return true;
        }

        const userRefresh = await firstValueFrom(this.authenticationService.refreshToken());
        if (userRefresh) {
            this.redirectUser(userRefresh, roles);
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    private redirectUser(user, roles) {
        if (user.forceChangePassword) {
            this.router.navigate(['/alterar-senha']);
            return false;
        }

        if (!user.economicGroup.emitters[0].cadastroCompleto && sessionStorage.getItem('licenca') != '2') {
            this.router.navigate(['/completar-cadastro']);
            return false;
        }

        if (roles.length > 0 && !roles.every((role: string) => user.roles.includes(role))) {
            return false;
        }
        return true;
    }
}
