import { firstValueFrom } from 'rxjs';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { pt_BR, provideNzI18n, NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask, IConfig } from 'ngx-mask';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { jwtInterceptor } from './helpers/jwt.interceptor';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AuthenticationService } from './services/authentication/authentication.service';

registerLocaleData(pt);

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

const maskConfig: Partial<IConfig> = {
    validation: false,
    dropSpecialCharacters: true,
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideNzI18n(pt_BR),
        importProvidersFrom([FormsModule, NzIconModule.forRoot(icons), NzToolTipModule]),
        provideEnvironmentNgxMask(maskConfig),
        provideAnimations(),
        provideHttpClient(withInterceptors([jwtInterceptor])),
        { provide: NZ_I18N, useValue: pt_BR },
        {
            provide: APP_INITIALIZER,
            useFactory: (authenticationService: AuthenticationService) => {
                return async (): Promise<any> => {
                    return await firstValueFrom(authenticationService.refreshToken());
                };
            },
            multi: true,
            deps: [AuthenticationService],
        },
    ],
};
