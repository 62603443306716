import { Routes } from '@angular/router';
import { ERoles } from './enums/roles.enum';
import { AuthGuard } from './guards/auth.guard';
import { AuthenticationComponent } from './layouts/authentication/authentication.component';
import { DomeComponent } from './layouts/dome/dome.component';

export const routes: Routes = [
    // Rota base (login)
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

    // Rotas com menu e navbar deverão ser inseridas como filhas do Layout Dome
    {
        path: '',
        component: DomeComponent,
        children: [
            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.DASHBOARD] },
                loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
            },
            {
                path: 'compras',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.NOTA_DE_ENTRADA] },
                loadChildren: () => import('./pages/compras/compras.module').then((m) => m.ComprasModule),
            },
            {
                path: 'vendas',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.NOTA_DE_SAIDA] },
                loadChildren: () => import('./pages/vendas/vendas.module').then((m) => m.VendasModule),
            },
            {
                path: 'insights',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.INSIGHTS] },
                loadChildren: () =>
                    import('./pages/insights-tributarios/insights-tributarios.module').then((m) => m.InsightsTributariosModule),
            },
            {
                path: 'obrigacoes',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.CALENDARIO] },
                loadChildren: () => import('./pages/obrigacoes-fiscais/obrigacoes-fiscais.module').then((m) => m.ObrigacoesFiscaisModule),
            },
            {
                path: 'integracao',
                canActivate: [AuthGuard],
                data: { roles: [ERoles.INTEGRACAO_CONTADOR, ERoles.NOTA_DE_ENTRADA] },
                loadChildren: () =>
                    import('./pages/integracao-contador/integracao-contador.module').then((m) => m.IntegracaoContadorModule),
            },
            {
                path: 'meus-dados',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/meus-dados/meus-dados.module').then((m) => m.MeusDadosModule),
            },
            {
                path: 'gerenciar-filiais',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/gerenciar-filiais/gerenciar-filiais.module').then((m) => m.GerenciarFiliaisModule),
            },
            {
                path: 'gerenciar-usuarios',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/gerenciar-usuarios/gerenciar-usuarios.module').then((m) => m.GerenciarUsuariosModule),
            },
            {
                path: 'gerenciar-perfis',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/gerenciar-perfis/gerenciar-perfis.module').then((m) => m.GerenciarPerfisModule),
            },
            {
                path: 'taxes',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/av-tax-compliance/av-tax-compliance.module').then((m) => m.AvTaxComplianceModule),
            },
            {
                path: 'faq',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqModule),
            },
            {
                path: 'bloqueio',
                canActivate: [AuthGuard],
                data: { roles: [] },
                loadChildren: () => import('./pages/bloqueio/bloqueio.module').then((m) => m.BloqueioModule),
            },
        ],
    },

    // Demais Rotas
    {
        path: '',
        component: AuthenticationComponent,
        children: [
            { path: 'login', loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule) },
            {
                path: 'completar-cadastro',
                loadChildren: () => import('./pages/completar-cadastro/completar-cadastro.module').then((m) => m.CompletarCadastroModule),
            },
            {
                path: 'alterar-senha',
                loadChildren: () =>
                    import('./pages/forcar-alterar-senha/forcar-alterar-senha.module').then((m) => m.ForcarAlterarSenhaModule),
            },
        ],
    },

    // Rota de erro
    { path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule) },

    // Fallback para login ou futura página de 404
    { path: '**', redirectTo: 'not-found', pathMatch: 'full' },
];
